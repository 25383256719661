import Heading from "../../../Components/Heading/Heading";
import ModalTypesOfServices from "./Modal/ModalTypesOfServices";
import TableTypesOfServices from "./TableTypesOfServices";

function TypesOfServices() {
  return (
    <>
      <Heading heading="انواع  الخدمات" />
      <ModalTypesOfServices />
      <TableTypesOfServices />
    </>
  );
}

export default TypesOfServices;
