import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../Components/RootLayout/RootLayout";
import { ErrorPage } from "./ErrorPage";
import Home from "../Page/Home";
import Login from "../Page/Login";
import Registration from "../Page/Registration";
import Dashboard from "../Dashboard";
import Customer from "../Dashboard/CustomerData/Customer";
import SystemUsers from "../Dashboard/SystemSettings/SystemUsers";
import CitySettings from "../Dashboard/SystemSettings/CitySettings";
import RegionSettings from "../Dashboard/SystemSettings/RegionSettings";
import TypesOfServices from "../Dashboard/SystemSettings/TypesOfServices";
import ServiceSettings from "../Dashboard/SystemSettings/ServiceSettings";
import CommissionSettings from "../Dashboard/SystemSettings/CommissionSettings";
import AccountStatement from "../Dashboard/CustomerData/AccountStatement";
import ServiceProviders from "../Dashboard/ServiceProviders/ServiceProviders";
import ServiceProviderStatement from "../Dashboard/ServiceProviders/ServiceProviderStatement";
import ExecutedInvoices from "../Dashboard/ServiceBill/ExecutedInvoices";
import CancelledInvoices from "../Dashboard/ServiceBill/CancelledInvoices";
import BillingReport from "../Dashboard/ServiceBill/BillingReport";
import IncomingTransfer from "../Dashboard/Accounts/IncomingTransfer";
import OutgoingTransfer from "../Dashboard/Accounts/OutgoingTransfer";
import Reports from "../Dashboard/Accounts/Reports";
import ComplaintsAndSuggestions from "../Dashboard/Accounts/ComplaintsAndSuggestions";
import SliderSettings from "../Dashboard/SystemSettings/SliderSettings";
import NewsSettings from "../Dashboard/SystemSettings/NewsSettings";
import ArticleSettings from "../Dashboard/SystemSettings/ArticleSettings";
import Ourservices from "../Page/Ourservices";
import AboutUs from "../Page/ِAboutUs";
import Verification from "../Page/Verification";
import VerificationCode from "../Page/VerificationCode";
import Profile from "../Page/MyProfile";
import ProfileLayout from "../Components/RootLayout/ProfileLayout";
import MyProfile from "../Page/MyProfile";
import AddImageProfile from "../Page/AddImageProfile";
import AddNameProfile from "../Page/AddNameProfile";
import AddEmailProfile from "../Page/AddEmailProfile/AddEmailProfile";
import ValidationEmailProfile from "../Page/AddEmailProfile/ValidationEmailProfile";
import AddPhoneProfile from "../Page/AddPhoneProfile/AddPhoneProfile";
import AddGenderProfile from "../Page/AddGenderProfile/AddGenderProfile";
import ContactUs from "../Page/ContactUs";
import Categories from "../Page/Categories";
import PlaceHolder from "../Page/PlaceHolder";
import CompanyCatogery from "../Page/CompanyCatogery";
import OrderDetails from "../Page/OrderDetails";
import Worker from "../Page/JoinWorker";
import JoinWorker from "../Page/JoinWorker";
import WorkerLogin from "../Page/WorkerLogin";
import Workerhome from "../Page/Workerhome/Workerhome";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },

      { path: "ourservices", element: <Ourservices /> },
      { path: "aboutUs", element: <AboutUs /> },
      { path: "Login", element: <Login /> },
      { path: "verification", element: <Verification /> },
      { path: "verificationCode", element: <VerificationCode /> },
      { path: "contactUs", element: <ContactUs /> },
      { path: "categories", element: <Categories /> },
      { path: "placeHolder", element: <PlaceHolder /> },
      { path: "companyCatogery", element: <CompanyCatogery /> },
      { path: "orderDetails", element: <OrderDetails /> },
      { path: "worker", element: <JoinWorker /> },
      { path: "workerLogin", element: <WorkerLogin /> },
      // { path: "workerhome", element: <Workerhome /> },




      { path: "Registration", element: <Registration /> },
    ],
  },
  {
    path: "profile",
    element: <ProfileLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/profile", element: <MyProfile /> },
      { path: "/profile/addimage", element: <AddImageProfile /> },
      { path: "/profile/addNameProfile", element: <AddNameProfile /> },
      { path: "/profile/addEmailProfile", element: <AddEmailProfile /> },
      { path: "/profile/ValidationEmailProfile", element: <ValidationEmailProfile /> },
      { path: "/profile/addPhoneProfile", element: <AddPhoneProfile /> },
      { path: "/profile/addGenderProfile", element: <AddGenderProfile /> },

    ]

  },
  {
    path: "Dashboard",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
    children: [
      //
      // { path: "SystemUsers", element: <SystemUsers /> },
      //  {{اعدادات النظام}}
      // مستخدمي النظام
      { path: "SystemUsers", element: <SystemUsers /> },
      // اعدادات المدينه
      { path: "CitySettings", element: <CitySettings /> },
      // اعدادات المنطقه
      { path: "RegionSettings", element: <RegionSettings /> },
      // انوع الخدمات
      { path: "TypesOfServices", element: <TypesOfServices /> },
      // اعدادت الخدمات
      { path: "ServiceSettings", element: <ServiceSettings /> },
      //  اعدادت العموله
      { path: "CommissionSettings", element: <CommissionSettings /> },
      //   اعدادات السليد
      { path: "SliderSettings", element: <SliderSettings /> },
      //   اعدادات الاخبار
      { path: "NewsSettings", element: <NewsSettings /> },
      //   اعدادات المقال
      { path: "ArticleSettings", element: <ArticleSettings /> },

      // {{بيانات العملاء}}
      // حسابات العملاء
      { path: "Customer", element: <Customer /> },
      //  كشف حساب
      { path: "AccountStatement", element: <AccountStatement /> },
      // {{ مزودى الخدمه }}
      //  مزودى الخدمه
      { path: "ServiceProviders", element: <ServiceProviders /> },
      //  كشف حساب مزود الخدمه
      {
        path: "ServiceProviderStatement",
        element: <ServiceProviderStatement />,
      },
      // {{فاتوره الخدمات}}
      // الفواتير المنفذه
      {
        path: "ExecutedInvoices",
        element: <ExecutedInvoices />,
      },
      // الفواتير الملغاه
      {
        path: "CancelledInvoices",
        element: <CancelledInvoices />,
      },
      // تقرير الفواتير
      {
        path: "BillingReport",
        element: <BillingReport />,
      },
      // {{ الجسابات }}
      // تحويل وارد
      {
        path: "IncomingTransfer",
        element: <IncomingTransfer />,
      },
      // تحويل صادر
      {
        path: "OutgoingTransfer",
        element: <OutgoingTransfer />,
      },
      // تقرير
      {
        path: "Reports",
        element: <Reports />,
      },
      // الشكاوى والاقترحات
      {
        path: "ComplaintsAndSuggestions",
        element: <ComplaintsAndSuggestions />,
      },
    ],
  },
]);
