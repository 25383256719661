import Heading from "../../../Components/Heading/Heading";
import ModalRegionSettings from "./Modal/ModalRegionSettings";
import TableRegionSettings from "./TableRegionSettings";

function RegionSettings() {
  return (
    <>
      <Heading heading="إعدادت المناطق" />
      <ModalRegionSettings />
      <TableRegionSettings />
    </>
  );
}

export default RegionSettings;
