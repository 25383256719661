import Heading from "../../../Components/Heading/Heading";
import ModalSystemUsers from "./Modal/ModalSystemUsers";
import TableSystemUsers from "./TableSystemUsers";

function SystemUsers() {
  return (
    <>
      <Heading heading="مستخدمي النظام" />
      <ModalSystemUsers />
      <TableSystemUsers />
    </>
  );
}

export default SystemUsers;
