
import React, { useEffect, useState } from 'react';
import type { DrawerProps, RadioChangeEvent } from 'antd';
import { Button, Divider, Drawer, Radio, Space } from 'antd';
import catogery1 from '../../assets/Png/catogery1.png'
import catogery2 from '../../assets/Png/catogery2.png'
import order1 from '../../assets/Png/order1.jpeg'
import order2 from '../../assets/Png/order2.png'
import './Categories.scss'
import { Link } from 'react-router-dom';

const CategoriesDrawer: React.FC = () => {
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState<DrawerProps['placement']>('left');

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	const onChange = (e: RadioChangeEvent) => {
		setPlacement(e.target.value);
	};

	return (
		<div className='CategoriesDrawer'>
			<Button type="primary" onClick={showDrawer} className='bg-[#0B247C] text-[#ffff] font-bold pr-10 pl-10'>
				اضافة
			</Button>
			<Drawer
				// title="الطلبات"
				title="الطلبات"
				placement={"right"}
				closable={false}
				onClose={onClose}
				open={open}
				key={placement}
			>
				<div className='w-full pb-16'>
					<div className='flex pr-3 pl-3 justify-center flex-col items-center gap-5 border-b-[1px] solid border-[#ccc] pb-5'>
						<div className='h-[100px] w-full'>
							<img src={catogery1} className='h-full w-full object-cover' alt="" />
						</div>
						<p className='font-bold tex-[12px]'>
							ضيـافة وقـهـوة
						</p>
					</div>
					<div className='flex pr-3 pt-3 pl-3 justify-center flex-col items-center gap-5 border-b-[1px] solid border-[#ccc] pb-5'>
						<div className='h-[100px] w-full'>
							<img src={catogery2} className='h-full w-full object-cover' alt="" />
						</div>
						<p className='font-bold tex-[12px]'>
							حفله شواء
						</p>
					</div>
					<div className='flex pr-3 pl-3 pt-3 justify-center flex-col items-center gap-5 border-b-[1px] solid border-[#ccc] pb-5'>
						<div className='h-[100px] w-full'>
							<img src={order1} className='h-full w-full object-cover' alt="" />
						</div>
						<p className='font-bold tex-[12px]'>
							تجهيز خيام
						</p>
					</div>
					<div className='flex pr-3 pl-3 pt-3 justify-center flex-col items-center gap-5 border-b-[1px] solid border-[#ccc] pb-5'>
						<div className='h-[100px] w-full'>
							<img src={order2} className='h-full w-full object-cover' alt="" />
						</div>
						<p className='font-bold tex-[12px]'>
							معد قهوه
						</p>
					</div>

				</div>
				<div className='fixed bottom-0 sh-do bg-[#fff] h-[70px] w-[250px] flex justify-center items-center '>
					<Link to={"/placeHolder"} type="primary" onClick={showDrawer} className='bg-[#0B247C] text-[#ffff] font-bold pt-1 pb-1 rounded-[4px] pr-5 pl-5'>
						تاكيد الطلب
					</Link >
				</div>
			</Drawer>
		</div>
	);
};

export default CategoriesDrawer;