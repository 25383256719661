import Heading from "../../../Components/Heading/Heading";
import ModalServiceSettings from "./Modal/ModalServiceSettings";
import TableServiceSettings from "./TableServiceSettings";

function ServiceSettings() {
  return (
    <>
      <Heading heading="الخدمات" />
      <ModalServiceSettings />
      <TableServiceSettings />
    </>
  );
}

export default ServiceSettings;
